body {
    font-family: 'Open Sans'
}

.App {
    margin: 20px;
}

.ColorsList {
    background-color: white;
    margin: 20px 0px !important;
}

.bulb {
    width: 55px !important;
    height: 55px !important;
}